<script>
    import { user, accessToken } from './stores.js';

    let donetext = "";

    let newdone
    export let dones;
    const handleSubmit = async () => {
        let newDonePromise = await fetch('/dones', {
            method: 'POST',
            headers: {
            "Content-type": "application/json; charset=UTF-8",
            'Authorization': 'Bearer '+$accessToken
            },
            body: JSON.stringify({done:donetext, user_id: $user.app_id})
        });

        const d = await newDonePromise.json();

        dones = [...dones, d]
    }     

  const donesamples = ["Met a unicorn", "Made the big sale", "Started road trip"]

</script>

<ul>
    <p>Add a new done:</p>
    <form on:submit|preventDefault={handleSubmit}>
      <input id="donetext" type="text" maxlength="255" placeholder="Met a unicorn" bind:value={donetext}>
      <button type="submit">Submit</button>
    </form>
</ul>