<script>
    import { user, accessToken } from './stores.js';

    let searchText = "";
    let currDay = ""

    export let dones;
    const handleSubmit = async () => {
        if(searchText == "") {
          return []
        }  

        let searchPromise = await fetch('/search', {
            method: 'POST',
            headers: {
            "Content-type": "application/json; charset=UTF-8",
            'Authorization': 'Bearer '+$accessToken
            },
            body: JSON.stringify({searchText:searchText,  user_id: $user.app_id})
        });

        const d = await searchPromise.json();
        
        // dones = d.Items

        var donesDisp = {}
        for(const didx in d.Items) {
          const done = d.Items[didx]
          const ts = new Date(done.timestamp).toDateString()

          if(currDay === "" || ts != currDay) {
            donesDisp[ts] = [done];
            currDay = ts;
          }
          else {
            donesDisp[ts].push(done);
          }
        }
        // console.log("DD")
        // console.log(JSON.stringify(donesDisp));
        dones = donesDisp;


        
    }     

    const resetDones = async () => {
      const doneUrl = "/dones?user_id="+$user.app_id;
			fetch(doneUrl, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer '+$accessToken
				}
			})
			.then(resp => resp.json())
			.then(d => dones = d);
    }

    const clearSearch = async () => {
      searchText = "";
      resetDones();
    }

</script>

<span style="float:right;">
    <p>Search dones:</p>
    <form on:submit|preventDefault={handleSubmit}>
      <input id="searchText" type="text" maxlength="255" bind:value={searchText}>
      <button type="submit">Search</button>
      <button on:click={clearSearch}>Clear</button>
    </form>
    
</span>