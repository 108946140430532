<script>
  import { onMount } from "svelte";

  export let doneCal;

</script>

<style>
  div {
    border: 2px solid;
    text-align: center;
    font-size: x-large;
    width: 3%;
    float: left;
  }
</style>

<p>Last 30 Day Streak : <a href="https://medium.com/@MrBuzzFactor/jerry-seinfelds-productivity-hack-don-t-break-the-chain-8d1944b8323d">Don't break the chain!</p>
<section id="donecal">
  {#each doneCal as done}
    <div>
    {#if done == 1}
    X
    {:else}
    0
    {/if}
    </div>
  {/each}
</section>
