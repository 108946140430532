<script>

    import { user } from './stores.js';
    import { fetchAuthConfig } from './shared';

    let email, password, full_name;
    let subError = "";

    const submitForm = async () => {
        
        let newuserPromise = await fetch('/users', {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({
               email: email
            })
        });
        
        let newuser = await newuserPromise.json()
        console.log(newuser)
        const authConfig = fetchAuthConfig();
        let userBody = JSON.stringify({
                client_id: authConfig.AUTH0_CLIENT_ID,
                email: email,
                password: password,
                connection: 'Username-Password-Authentication',
                name: full_name,
                user_metadata: {app_id: newuser.id}
            })
        console.log(userBody)
        let remoteUser = await fetch('https://dev-rwcqxven.auth0.com/dbconnections/signup', {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: userBody
        });

        const result = await remoteUser.json();
        console.log(result);
        if(result.statusCode != 200) {
            subError = result.message;
        }
        else {
            console.log("done")
            subError = "User created!"

        }
    }

</script>

<style>
    .main-container {
        display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      grid-gap: 20px;
    }

    #signup-box {
        grid-column: 1;
        grid-row: 1;
        font-family: 'B612', sans-serif;
    }

    #copy-box {
        grid-column: 2;
        grid-row: 1;
        font-family: 'Volkhov', serif;
        font-size: larger;
    }

    #blank-box {
        grid-column: 3;
        grid-row: 1;
    }

    #bottomtext-box {
        height: 2em; 
        position: fixed; 
        bottom:0%;
        font-family: 'Volkhov', serif;
        font-size: smaller;
    }

</style>

<div class="main-container">

    <div id="copy-box">
        DailyDones is a service where we send an email a day for you to make a note of what you did that day.<br>
        <br>
        Just a couple of lines a day helps you reflect on what you've accomplished and as time goes by we'll be 
        able to remind you of things you've done.<br>
        <br>
        Here's a couple of small example Dones:<br>
        <img src="assets/ddones_shot.png" alt="Example of 2 days of Dones">
        <br>
        Once you sign up, you'll get an email in the evening that you just need to respond to.
    </div>

    <br>
    <br>

    <div id="signup-box">
        <form id="signup" on:submit|preventDefault={submitForm}>
            <fieldset>
                <legend><b>Sign Up</b></legend>
                <p>
                <input type="email" id="signup-email" placeholder="Email" bind:value={email} required/>
                </p>
                <p>
                <input type="password" id="signup-password" placeholder="Password" bind:value={password}
                        required/>
                </p>
                <p>
                <input type="text" id="name" placeholder="Full name" required bind:value={full_name} />
                </p>
                <input type="submit" value="Sign Up"/>
            </fieldset>
        </form>
        <br />
        <span>{subError}</span>
    </div>

    <div id="blank-box"></div>

    <div id="bottomtext-box">
    DailyDones is a GrommesMade creation. It's in development right now so please excuse a little mess.
    </div>

</div>