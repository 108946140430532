<script>
	import { onMount } from "svelte";
	import { slide } from 'svelte/transition';

	import { user, isAuthenticated, accessToken } from "./stores"
	import { setupAuth0 } from './shared'

	export let dones;

	let currDay = ""

	async function getDones(days) {
		let auth0 = await setupAuth0();

		let theuser = await auth0.getUser();
		theuser.app_id = theuser['https://www.dailydones.club/user_metadata'].app_id
		user.set(theuser);

		let theaccesstoken = await auth0.getTokenSilently({audience: "https://dailydones.vercel.app/"});
		accessToken.set(theaccesstoken);

		const doneUrl = "/dones?user_id="+$user.app_id+"&days="+days;
		fetch(doneUrl, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer '+$accessToken
			}
		})
		.then(resp => resp.json())
		.then(d => {

			var donesDisp = {}
			for(const didx in d) {
				const done = d[didx]
				const ts = new Date(done.timestamp).toDateString()

				if(currDay === "" || ts != currDay) {
					donesDisp[ts] = [done];
					currDay = ts;
				}
				else {
					donesDisp[ts].push(done);
				}
			}
			// console.log("DD")
			// console.log(JSON.stringify(donesDisp));
			dones = donesDisp;
		})
		.catch(err => console.log("ERR: ", err));
		
	}

	let state = "week";
	function switchTo(numDays, currState) {
		if(state == currState)
			return;

		state = currState;

		getDones(numDays);
	}

	function switchToWeek() {
		switchTo(7, 'week');
	}

	function switchToMonth() {
		switchTo(30, 'month');
	}

	function switchToQuarter() {
		switchTo(90, 'quarter');
	}

	function switchToYear() {
		switchTo(365, 'year');
	}

	onMount( async () => {
		switchToMonth();
	})
</script>

<style>
	.activated {
		font-size: bigger;
	  font-weight: bold;
	}

	.deactivated {
		font-size: smaller;
	}

</style>

<ul>
	<p>
	Show how many Dones: 
	<a href="/#" class="{state == 'week' ? 'activated' : 'deactivated'}" on:click={switchToWeek}>Week</a> | 
		<a href="/#" class="{state == 'month' ? 'activated' : 'deactivated'}" on:click={switchToMonth}>Month</a> |
		<a href="/#" class="{state == 'quarter' ? 'activated' : 'deactivated'}" on:click={switchToQuarter}>Quarter</a> |
		<a href="/#" class="{state == 'year' ? 'activated' : 'deactivated'}" on:click={switchToYear}>Year</a> |
	</p>

	<section id="doneslist">
		{#each Object.keys(dones) as ddate}
			<b>{new Date(ddate).toLocaleDateString("en-US", {'timeZone':'UTC'})}</b><br>
			<ul>
			{#each dones[ddate] as done}
				<li>{done.done}</li>
			{/each}
			</ul>
		{/each}
	</section>
</ul>