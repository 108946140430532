<script>
	import NewDone from "./NewDone.svelte";
	import DoneList from "./DoneList.svelte";
	import DoneCal from "./DoneCal.svelte";
	import Login from "./Login.svelte";
	import Signup from "./Signup.svelte";

	import { onMount, onDestroy } from "svelte";

	import { user, isAuthenticated, accessToken } from "./stores"
	import { setupAuth0 } from './shared'
	import SearchDones from "./SearchDones.svelte";

	let dones = []
	let doneCal = new Array(30).fill(0);
	onMount( async () => {
		let auth0 = await setupAuth0();

		if(Object.keys($user).length === 0 && $user.constructor === Object) {
			console.log("no user")

			let theuser = await auth0.getUser();
			console.log(theuser);
			theuser.app_id = theuser['https://www.dailydones.club/user_metadata'].app_id
			user.set(theuser);

			let newDonePromise = await fetch('/session', {
				method: 'post',
				headers: {
				"Content-type": "application/json; charset=UTF-8"
				},
				body: JSON.stringify({user: theuser})
			})
			.then((returnedResponse) => {
				// console.log(returnedResponse);
			})
			.catch((error) => {
				// Your error is here!
				console.log(error);
			});

			// console.log($user)
		}

		const isA = await auth0.isAuthenticated();
		isAuthenticated.set(isA)
		// console.log("isA: "+isA)

		if(isA && !$accessToken) {
			let theaccesstoken = await auth0.getTokenSilently({audience: "https://dailydones.vercel.app/"});
			accessToken.set(theaccesstoken);
		}
	});

	const unsubscribe = accessToken.subscribe(value => {
		// console.log("$user");
		// console.log($user);
		// console.log(value);
		if(value && $user) {
			// const doneUrl = "/api/dones?user_id="+$user.app_id;
			// fetch(doneUrl, {
			// 	method: 'GET',
			// 	headers: {
			// 		'Authorization': 'Bearer '+value
			// 	}
			// })
			// .then(resp => resp.json())
			// .then(d => dones = d);

			// calendar
			const doneCalUrl = "/dones?user_id="+$user.app_id+"&days=30";
			fetch(doneCalUrl, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer '+value
				}
			})
			.then(resp => resp.json())
			.then(dones => {

			  const now = moment().tz('America/Los_Angeles').subtract(1, 'days');

			  var prevDay = now;
				for(var i=0; i<dones.length; i++) {
					var ts = moment(dones[i].timestamp).add(7, 'hours');

				  var idx = now.diff(ts, 'days')
				  doneCal[idx] = 1;
				}

			})

		}
	});

	onDestroy(unsubscribe);

</script>

<style>
	.main-container {
		display: grid;
	  grid-template-columns: 1fr 1fr 2fr;
	  grid-gap: 20px;
	}

	.login-box {
		grid-column: 1;
		grid-row: 1;
	}

	.signup-box {
		grid-column: 1;
		grid-row: 1;
	}

	.donecal-box {
		grid-column: 2 / 4;
		grid-row: 1;
	}

	.donelist-box {
		grid-column: 2 / 4;
		grid-row: 2;
	}

</style>

<div class="main-container">
	<div class="login-box">
		<Login/> { $user && $user.name ?  $user.name : "" }
	</div>
	<div class="donecal-box">
		{#if $isAuthenticated}
		<DoneCal {doneCal}/>
		{/if}
	</div>

	<div class="donelist-box">
		{#if $isAuthenticated}
			<SearchDones bind:dones/>
			<NewDone bind:dones/>
			
			<ul>
				<DoneList {dones}/>
			</ul>
		{:else}
			<div class="signup-box">
				<Signup/>
			</div>
		{/if}
	</div>
</div>
