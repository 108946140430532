<script>
    import { onMount } from "svelte";

    import { user, isAuthenticated, accessToken } from './stores';
    import { setupAuth0 } from './shared'

    let auth0;
    $: $isAuthenticated = auth0 ? auth0.isAuthenticated() : false;

    const login = async () => {

        // location.href='/login'

        await auth0.loginWithPopup({
            redirect_uri: window.location.origin
        });

        let theuser = await auth0.getUser();
        console.log("theuser")
        console.log(theuser)
        theuser.app_id = theuser['https://www.dailydones.club/user_metadata'].app_id
        user.set(theuser);

        const isA = await auth0.isAuthenticated();
		isAuthenticated.set(isA)

        let theaccesstoken = await auth0.getTokenSilently({audience: "https://dailydones.vercel.app/"});
        accessToken.set(theaccesstoken);

        let newDonePromise = await fetch('/session', {
            method: 'post',
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({user: theuser})
        })
        .then((returnedResponse) => {
            console.log(returnedResponse);
        })
        .catch((error) => {
            // Your error is here!
            console.log(error);
        });
    };

    const logout = async () => {

        // location.href='/logout'

        auth0.logout({
            returnTo: 'https://dailydones.vercel.app'
        });

        user.set({});
        let d = await fetch('/session', {
            method: 'delete'
        });
    };

    onMount(async () => {
        auth0 = await setupAuth0();
        const isA = await auth0.isAuthenticated();
		isAuthenticated.set(isA)

        if(isA) {
            let theaccesstoken = await auth0.getTokenSilently({audience: "https://dailydones.vercel.app/"});
            accessToken.set(theaccesstoken);
        }
    });

</script>

<style>
    button {
        font-family: 'B612', sans-serif;
    }
</style>


<button id="btn-login" on:click={login} disabled="{!isAuthenticated}">Log in</button>
<button id="btn-logout" on:click={logout} disabled="{!isAuthenticated}">Log out</button>
<br />
<br />