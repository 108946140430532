
export async function setupAuth0() {
    const config = await fetchAuthConfig();

    return await createAuth0Client({
        domain: config.domain,
        client_id: config.clientId
    });
}

export async function fetchAuthConfig() {
    const fetchConfig = () => fetch("/auth_config.json");

    const response = await fetchConfig();
    const config = await response.json();

    return config;
}